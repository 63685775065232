/* AWS */
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { AWSAppSyncClient } from 'aws-appsync'
import App from './App.vue'
/* CSFrontendComponents */
import CSFrontendComponents from '@content-software/cs-frontend-components'
import '@content-software/cs-frontend-components/dist/CSFrontendComponents.css'
/* CSQuantengine2.0 */
import CSQuantengineFrontendComponents2 from '@content-software/csquantengine-frontend/src/csQuantenginePlugin'
import '@content-software/csquantengine-frontend/dist/CSQuantengine.css'
import StringFormatter from '@content-software/csquantengine-frontend/src/js-helpers/stringFormatter'
/* CSDistributionService */
import CSDistributionServiceFrontendComponents from '@content-software/csdistributionservice-frontend/src/csDistributionServiceFrontendPlugin'
import '@content-software/csdistributionservice-frontend/dist/CSDistributionServiceFrontendComponents.css'
/* CSLegal */
import CSLegalFrontendComponents from '@content-software/cslegal-frontend/src/csLegalFrontendPlugin'
import '@content-software/cslegal-frontend/dist/CSLegalFrontendComponents.css'
import CSIcons from '@content-software/cslegal-frontend/src/js/CsIcons'
/* CSDataWarehouse */
import CSDataWarehouseFrontendComponents from '@content-software/csdatawarehouse-frontend/src/csDataWarehousePlugin'
import '@content-software/csdatawarehouse-frontend/dist/CSDataWarehouse.css'
/* CSResearch */
import CSResearchHostingFrontendComponents from '@content-software/csresearch-frontend/src/csResearchHostingPlugin'
import '@content-software/csresearch-frontend/dist/CSResearch.css'
/* CSChronicles */
import CSChroniclesFrontendComponents from '@content-software/cschronicles-frontend/src/csChroniclesFrontendPlugin'
import '@content-software/cschronicles-frontend/dist/CSChroniclesFrontendComponents.css'
/* Project internal files */
import CSBaseLogger from './js/csbase-logger'
import PermissionHelper from './js/permissions/permissionHelper'
import router from './router'
import store from './store'
/* Other */
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-pro/css/all.css'
/* Vue related imports */
import FlagIcon from 'vue-flag-icon'
import Notifications from 'vue-notification'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueI18n from 'vue-i18n'
import Vuetify from './plugins/vuetify'

let BASE_CLIENT

fetch('config.json').then((json) => {
  json.json().then(async (parameters) => {
    Vue.prototype.$BACKEND_PARAMETERS = parameters

    // const cookieStorage = new CookieStorage({
    //     secure: false,
    //     domain: parameters.BaseDomain || 'localhost',
    //     sameSite: "lax",
    //     expires: 365,
    //     path: "/"
    // });

    const awsmobile = {
      aws_project_region: 'eu-central-1',
      aws_appsync_graphqlEndpoint: [parameters.AppSyncURL],
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
      aws_cognito_region: 'eu-central-1',
      aws_user_pools_id: parameters.CognitoUserPoolID,
      aws_user_pools_web_client_id: parameters.CognitoClientID,
      oauth: {}
      // "cookieStorage": cookieStorage,
      // "storage": cookieStorage,
    }
    Amplify.configure(awsmobile)
    Auth.configure(awsmobile)
    /* Configure baseClient (AppSyncClient) */
    const config = {
      url: parameters.AppSyncURL,
      region: 'eu-central-1',
      auth: {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => {
          const currentSession = await Auth.currentSession()
          return currentSession.getAccessToken().getJwtToken()
        }
      },
      disableOffline: true
    }
    const options = {
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network'
        }
      }
    }
    // Creates the vue apollo provider
    BASE_CLIENT = new AWSAppSyncClient(config, options)
    let clients = {
      BASE_CLIENT: BASE_CLIENT
    }
    const appsyncProvider = new VueApollo({
      clients: clients,
      defaultClient: BASE_CLIENT
    })
    /* Vue use objects */
    Vue.use(Amplify)
    Vue.config.productionTip = false
    Vue.use(CSFrontendComponents, { store: store })
    Vue.use(CSBaseLogger)
    Vue.use(FlagIcon)
    Vue.use(Notifications)
    Vue.use(CSQuantengineFrontendComponents2, { router })
    Vue.use(CSDistributionServiceFrontendComponents, { router })
    Vue.use(CSLegalFrontendComponents, { router, store })
    Vue.use(CSDataWarehouseFrontendComponents, { router })
    Vue.use(CSResearchHostingFrontendComponents, { router })
    Vue.use(CSChroniclesFrontendComponents, { router })
    Vue.use(VueApollo)
    Vue.use(VueI18n)
    Vue.use(Vuetify)
    const i18n = new CSFrontendComponents.CSBasicsI18n(
      require('./lang.json'),
      store
    ).i18n
    Vue.prototype.$stringFormatter = StringFormatter
    Vue.prototype.$csDialog = CSFrontendComponents.CSDialog
    Vue.prototype.$awsAppSyncClientConfig = { config, options }
    Vue.prototype.$csIcons = CSIcons
    Vue.prototype.$csBasePermissionHelper = PermissionHelper
    new Vue({
      apolloProvider: appsyncProvider,
      i18n,
      vuetify: Vuetify,
      router,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  })
})
// Needed for global access to Apollo Client
export { BASE_CLIENT }
